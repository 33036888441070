<template>
    <c-panel class="spacing-none">
        <h1>Artists</h1>
        <c-grid class="widths-20-all widths-25-md widths-33-sm" v-if="randomizedArtists">
            <template v-for="artist in randomizedArtists" :key="artist.id">
                <c-grid-item v-if="artist.data && artist.data.profilePic">
                    <div class="artist" @click="$router.push('/artist/' + artist.id)">
                        <div class="profile-pic">
                            <c-image :image="artist.data.profilePic" />
                        </div>
                        <div class="name">
                            <h3>{{ artist.data.name }}</h3>
                            <p class="text-light">{{ artist.data.location }}</p>
                        </div>
                    </div>
                </c-grid-item>
            </template>
        </c-grid>
    </c-panel>
    <c-panel>
        <h1>Artwork</h1>
        <div class="gallery" ref="gallery" v-if="(randomizedProducts?.length ?? 0) > 0">
            <template v-for="product in randomizedProducts" :key="product.id">
                <div class="print" v-if="product.data && product.data.category == 'Gallery Print'">
                    <img
                        v-if="product.data.images && product.data.images[0]"
                        @load="resizeGallery()"
                        @click="$router.push('/product/' + product.id)"
                        :src="product.data.images[0].thumbnail"
                    />
                </div>
            </template>
        </div>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import { Database } from "vuebase";
import { Artist, Product } from "../main";
import Masonry from "masonry-layout";

export default defineComponent({
    name: "Gallery",
    setup() {
        const database = inject(Database.InjectionKey);
        const artists = database?.collection<Artist>("artists").documents();
        const products = database?.collection<Product>("products").documents();
        const gallery = ref();
        const randomizedProducts = ref<Database.Document<Product>[]>();
        const randomizedArtists = ref<Database.Document<Artist>[]>();

        watch(
            () => artists,
            () => {
                if (artists && artists.length > 0) {
                    randomizedArtists.value = [...artists].sort(() => 0.5 - Math.random());
                }
            },
            { deep: true, immediate: true },
        );

        watch(
            () => products,
            () => {
                if (products && products.length > 0) {
                    randomizedProducts.value = [...products].sort(() => 0.5 - Math.random());
                }
            },
            { deep: true, immediate: true },
        );

        function resizeGallery() {
            new Masonry(gallery.value, {
                itemSelector: ".print",
                percentPosition: true,
            });
        }

        return {
            randomizedArtists,
            randomizedProducts,
            gallery,
            resizeGallery,
        };
    },
});
</script>

<style lang="scss">
@import "../../../coho-ui/src/styles/variables.scss";
@import "../../../coho-ui/src/styles/mixins.scss";

.artist {
    cursor: pointer;

    .profile-pic {
        border-radius: 1000px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        transition: all 300ms ease;
        overflow: hidden;

        &:hover {
            transform: scale(1.02);
        }
    }

    .name {
        margin-top: 12px;
        text-align: center;
    }
}

.gallery {
    margin: -5px;
}

.print {
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
    width: 33%;

    @include screen("md") {
        width: 50%;
    }

    @include screen("sm") {
        width: 100%;
    }

    img {
        display: block;
        object-fit: contain;
        width: 100%;
    }
}
</style>
